<template>
  <div>
    <div class="solutions-area section-width ptb-100">
      <div class="container">
        <div class="section-title">
          <h2>{{ $t("navbar.menu_2_3") }}</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
            suspendisse ultrices gravida.
          </p>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="category-navigate">
            <ul>
              <!-- <li class="btn btn-primary btn-lg"><a href="#" @click="goAnchor('.block1')">分類1</a></li>
                            <li class="btn btn-primary btn-lg"><a href="#" @click="goAnchor('.block2')">分類2</a></li>
                            <li class="btn btn-primary btn-lg"><a href="#" @click="goAnchor('.block3')">分類3</a></li>
                            <li class="btn btn-primary btn-lg"><a href="#" @click="goAnchor('.block4')">分類4</a></li> -->
              <a href="#" @click.prevent="changeSpace('展場空間')">
                <li
                  :class="[
                    'btn btn-primary btn-lg',
                    { active: selectdSpace == '展場空間' },
                  ]"
                >
                  {{ $t("section_1.title") }}
                </li>
              </a>
              <a href="#" @click.prevent="changeSpace('會議中心')">
                <li
                  :class="[
                    'btn btn-primary btn-lg',
                    { active: selectdSpace == '會議中心' },
                  ]"
                >
                  {{ $t("section_2.title") }}
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div class="row text-left">
          <div class="col-lg-12">
            <b-form-group>
              <b-form-tags
                id="tags-component-select"
                v-model="this.tagSelectedList.activity"
                size="mg"
                class="mb-2"
                add-on-change
                no-outer-focus
              >
                <template
                  v-slot="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    disabled,
                    removeTag,
                  }"
                >
                  <b-input-group size="mg" :prepend="$t('select.title_1')">
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || activityOptions.length === 0"
                      :options="activityOptions"
                      @change="selectedTagValue('activity', $event)"
                    >
                      <template #first>
                        <option disabled value="">{{
                          $t("select.optionDefault")
                        }}</option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mt-3"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="
                          removeTag(tag), removerTagValue('activity', tag)
                        "
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                        >{{ tag }}</b-form-tag
                      >
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </div>
          <div class="col-lg-12">
            <b-form-group v-show="false">
              <b-form-tags
                id="tags-component-select"
                v-model="this.tagSelectedList.device"
                size="mg"
                class="mb-2"
                add-on-change
                no-outer-focus
              >
                <template
                  v-slot="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    disabled,
                    removeTag,
                  }"
                >
                  <b-input-group size="mg" prepend="活動設備">
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || deviceOptions.length === 0"
                      :options="deviceOptions"
                      @change="selectedTagValue('device', $event)"
                    >
                      <template #first>
                        <option disabled value="">類別可複選</option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mt-3"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag), removerTagValue('device', tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                        >{{ tag }}</b-form-tag
                      >
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </div>
          <div class="col-lg-12">
            <b-input-group size="mg" :prepend="$t('select.title_2')">
              <b-form-input
                id="type-number"
                type="number"
                @input="changeCapacity"
                v-model="capacity"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              >
              </b-form-input>
            </b-input-group>
          </div>
        </div>
        <b-card-group deck class="mt-4 animated fadeInUp" :key="selectdSpace">
          <b-card>
            <b-img
              :src="spaceData['zh-tw'][selectdSpace].pic"
              fluid
              center
              alt="Fluid-grow image"
            ></b-img>
            <!-- <b-card-text class="mt-4" v-html="spaceData['zh-tw'][selectdSpace].content">
                        </b-card-text> -->
            <b-card-text class="mt-4" v-if="selectdSpace == '展場空間'">
              {{ $t("section_1.card.content") }}
            </b-card-text>
            <b-card-text class="mt-4" v-if="selectdSpace == '會議中心'">
              {{ $t("section_2.card.content") }}
            </b-card-text>
          </b-card>
        </b-card-group>
        <div class="row mt-4">
          <div
            class="mb-4 col-lg-4 animated fadeInUp"
            v-for="item in spaceList"
            :key="item.venue_space_id"
          >
            <router-link :to="`rental-venue/space/${item.venue_space_id}`">
              <div class="card act-card-hover">
                <img
                  class="card-img-top"
                  :src="item.path"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <small
                    ><i class="fas fa-door-open"></i
                    >{{ item.space_name }}</small
                  >
                  <!--
                                    <div class="widget-area">
                                        <div class="post-wrap pt-0">
                                            <div class="tagcloud">
                                                <a v-for="(tagItem, tagIndex) in item.activityTag" :key="tagIndex"><i
                                                        class="fas fa-users"></i> {{tagItem.tag_name}}
                                                    {{tagItem.capacity}} 人</a>
                                            </div>
                                        </div>
                                    </div>
                                    -->
                </div>
                <div class="card-footer text-right">
                  <span
                    v-for="(tagItem, tagIndex) in item.spaceTag"
                    :key="tagIndex"
                    class="badge badge-info"
                    ># {{ tagItem.tag_name }}</span
                  >
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div
          :class="[
            'row',
            'text-center',
            { 'is-show-next-page': isShowNextPage },
          ]"
        >
          <div class="col-lg-12 read-more">
            <button class="btn btn-warning" @click="getNextSpaceList()">
              {{ $t("section_2.pageBtn")
              }}<i class="fas fa-chevron-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const apiUrl = `${process.env.VUE_APP_API_URL}/venue`;

export default {
  name: "RentalVenueBody",
  components: {},
  data() {
    return {
      bannerTitle: "BOOKING<br>INQUIRY",
      borrowSpaceCrumb: ["租借場地"],
      spaceData: {
        "zh-tw": {
          展場空間: {
            pic: require("assets/project_images/pic/floorplan_1f.jpg"),
          },
          會議中心: {
            pic: require("assets/project_images/pic/floorplan_3f.jpg"),
          },
        },
      },
      selectdSpace: "展場空間",
      tagSelectionList: {
        space: [],
        activity: [],
        device: [],
      },
      tagSelectedList: {
        space: [],
        activity: [],
        device: [],
      },
      spaceList: [],
      page: 1,
      isShowNextPage: false,
      capacity: "",
    };
  },
  computed: {
    spaceOptions() {
      return this.tagSelectionList.space.filter(
        (opt) => this.tagSelectedList.space.indexOf(opt) === -1
      );
    },
    activityOptions() {
      return this.tagSelectionList.activity.filter(
        (opt) => this.tagSelectedList.activity.indexOf(opt) === -1
      );
    },
    deviceOptions() {
      return this.tagSelectionList.device.filter(
        (opt) => this.tagSelectedList.device.indexOf(opt) === -1
      );
    },
  },
  async mounted() {
    await this.getTag();
    this.changeSpace("展場空間");
  },
  methods: {
    changeSpace(spaceName) {
      this.selectdSpace = spaceName;
      this.page = 1;
      this.getSpaceList();
    },
    changeCapacity() {
      this.getSpaceList();
    },
    async getSpaceList() {
      const api = `${apiUrl}/space_list`;
      let postParams = {
        venue_id: 1,
        page: this.page,
        limit: "6",
        order_by: [
          {
            field: "post_date",
            method: "ASC",
          },
        ],
        filters: {
          tags: [
            [this.selectdSpace],
            this.tagSelectedList.activity,
            this.tagSelectedList.device,
          ],
        },
        language_id: this.$i18n.locale,
        image_width: 350,
      };
      var regular = /\d/g;
      //"uSer0910".match(/\d/g)
      if (this.capacity != 0 && regular.test(this.capacity)) {
        postParams.filters.capacity = {
          min: this.capacity,
          max: this.capacity,
        };
      }

      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response.data.list;
        let pageNumber = response["data"]["count"]["all"];
        this.showPageBtnCheck(pageNumber);
        if (this.page == 1) {
          this.spaceList = data;
        } else {
          const cardListStash = this.spaceList.concat(data);
          this.spaceList = cardListStash;
        }

        data.forEach((dataElement) => {
          dataElement.spaceTag = [];
          dataElement.activityTag = [];
          dataElement.deviceTag = [];

          dataElement.tag.forEach((tagElement) => {
            if (tagElement.tag_group != "") {
              let tagType = tagElement.tag_group.split(",")[0];
              let tagLang = tagElement.tag_group.split(",")[1];
              if (this.$i18n.locale == tagLang) {
                switch (tagType) {
                  case "場域類別":
                    dataElement.spaceTag.push(tagElement);
                    break;
                  case "活動類型":
                    dataElement.activityTag.push(tagElement);
                    break;
                  case "設備與服務":
                    dataElement.deviceTag.push(tagElement);
                    break;
                }
              }
            }
          });
        });
      });
    },
    async getTag() {
      const api = `${apiUrl}/tag_list`;
      let postParams = {
        venue_id: 1,
        page: 1,
        limit: "50",
        order_by: [
          {
            field: "start_time",
            method: "DESC",
          },
        ],
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response.data.list;

        data.forEach((element) => {
          //處理Tag類別清單

          if (element.tag_group != "") {
            let tagType = element.tag_group.split(",")[0];
            let tagLang = element.tag_group.split(",")[1];
            if (tagLang == this.$i18n.locale) {
              switch (tagType) {
                case "場域類別":
                  this.tagSelectionList.space.push(element.tag_name);
                  this.tagSelectedList.space.push(element.tag_name);
                  break;
                case "活動類型":
                  this.tagSelectionList.activity.push(element.tag_name);
                  // this.tagSelectedList.activity.push(element.tag_name);
                  break;
                case "設備與服務":
                  this.tagSelectionList.device.push(element.tag_name);
                  //this.tagSelectedList.device.push(element.tag_name);
                  break;
              }
            }
          }
        });
      });
    },
    removerTagValue(key, tag = "") {
      let valueTagStash = this.tagSelectedList[key].filter((item) => {
        return item !== tag;
      });
      this.tagSelectedList[key] = valueTagStash;
      this.page = 1;
      this.getSpaceList();
    },
    selectedTagValue(key, val) {
      this.tagSelectedList[key].push(val);
      this.page = 1;
      this.getSpaceList();
    },
    getNextSpaceList() {
      this.page = this.page + 1;
      this.getSpaceList();
    },
    showPageBtnCheck(pageNumber) {
      this.isShowNextPage = false;
      if (parseInt(pageNumber) / 6 <= this.page) {
        this.isShowNextPage = true;
      }
    },
  },
};
</script>

<style scoped>
.is-show-next-page {
  display: none;
}

.card-body small i {
  font-size: 18px;
  margin-right: 10px;
}

.widget-area .tagcloud a {
  display: inline-block;
  color: #666666;
  font-weight: normal;
  font-size: 14px !important;
  padding: 7px 10px;
  border: 1px dashed #eeeeee;
  margin-top: 10px;
  margin-right: 4px;
  text-transform: capitalize;
}

.is-show-next-page {
  display: none;
}

.badge {
  font-weight: 500;
}

.fa-eye {
  padding-top: 5px;
  float: right;
}

.read-more {
  border-top: 1px solid #ced4da;
  padding-top: 25px;
}

.fa-eye::before {
  margin-right: 5px;
}

.card-footer {
  background-color: #fff;
}

.card-img-top {
  object-fit: cover;
  height: 250px;
}

.solutions-area.section-width .container {
  max-width: 1000px;
}

.list-inline-item span {
  margin-bottom: 12px;
  padding: 6px 10px 8px 10px;
  font-size: 16px;
}

.form-control {
  height: 40px;
  background-color: #fff;
}

.form-group .form-control {
  border: 0px;
  padding: 0;
  background-color: transparent;
}

.b-form-tag > button.b-form-tag-remove {
  color: #fff;
}

.b-form-tag-content {
  color: #ffffff !important;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.in-btn {
  margin-left: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.badge-info {
  padding: 5px 8px;
  margin-right: 4px;
  font-size: 14px;
}

.card-footer {
  color: #b5bac1;
}

.card-body {
  padding: 10px 20px;
}

.card-body small {
  color: #b5bac1;
  font-family: Noto Sans, Apple Casual, Corbel;
  font-size: 16px;
}

.card-body .card-text {
  font-weight: bold;
  font-size: 16px;
}

.custom-select:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

.act-card-hover {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.5s;
}

.act-card-hover:hover {
  transform: scale(1.02, 1.02);
}

.card-columns {
  column-count: 3;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .card-columns {
    column-count: 2;
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
  .card-columns {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .card-columns {
    column-count: 1;
  }
}
</style>
