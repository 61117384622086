var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "section-title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_2_3")))]),
            _c("p", [
              _vm._v(
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
              )
            ])
          ]),
          _c("div", { staticClass: "row justify-content-center mt-5" }, [
            _c("div", { staticClass: "category-navigate" }, [
              _c("ul", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.changeSpace("展場空間")
                      }
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        class: [
                          "btn btn-primary btn-lg",
                          { active: _vm.selectdSpace == "展場空間" }
                        ]
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("section_1.title")) + " ")]
                    )
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.changeSpace("會議中心")
                      }
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        class: [
                          "btn btn-primary btn-lg",
                          { active: _vm.selectdSpace == "會議中心" }
                        ]
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("section_2.title")) + " ")]
                    )
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row text-left" }, [
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c(
                  "b-form-group",
                  [
                    _c("b-form-tags", {
                      staticClass: "mb-2",
                      attrs: {
                        id: "tags-component-select",
                        size: "mg",
                        "add-on-change": "",
                        "no-outer-focus": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var tags = ref.tags
                            var inputAttrs = ref.inputAttrs
                            var inputHandlers = ref.inputHandlers
                            var disabled = ref.disabled
                            var removeTag = ref.removeTag
                            return [
                              _c(
                                "b-input-group",
                                {
                                  attrs: {
                                    size: "mg",
                                    prepend: _vm.$t("select.title_1")
                                  }
                                },
                                [
                                  _c(
                                    "b-form-select",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            disabled:
                                              disabled ||
                                              _vm.activityOptions.length === 0,
                                            options: _vm.activityOptions
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.selectedTagValue(
                                                "activity",
                                                $event
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          value: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "select.optionDefault"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        "b-form-select",
                                        inputAttrs,
                                        false
                                      ),
                                      inputHandlers
                                    )
                                  )
                                ],
                                1
                              ),
                              tags.length > 0
                                ? _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline d-inline-block mt-3"
                                    },
                                    _vm._l(tags, function(tag) {
                                      return _c(
                                        "li",
                                        {
                                          key: tag,
                                          staticClass: "list-inline-item"
                                        },
                                        [
                                          _c(
                                            "b-form-tag",
                                            {
                                              attrs: {
                                                title: tag,
                                                disabled: disabled,
                                                variant: "info"
                                              },
                                              on: {
                                                remove: function($event) {
                                                  removeTag(tag),
                                                    _vm.removerTagValue(
                                                      "activity",
                                                      tag
                                                    )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(tag))]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: this.tagSelectedList.activity,
                        callback: function($$v) {
                          _vm.$set(this.tagSelectedList, "activity", $$v)
                        },
                        expression: "this.tagSelectedList.activity"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c(
                  "b-form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ]
                  },
                  [
                    _c("b-form-tags", {
                      staticClass: "mb-2",
                      attrs: {
                        id: "tags-component-select",
                        size: "mg",
                        "add-on-change": "",
                        "no-outer-focus": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var tags = ref.tags
                            var inputAttrs = ref.inputAttrs
                            var inputHandlers = ref.inputHandlers
                            var disabled = ref.disabled
                            var removeTag = ref.removeTag
                            return [
                              _c(
                                "b-input-group",
                                { attrs: { size: "mg", prepend: "活動設備" } },
                                [
                                  _c(
                                    "b-form-select",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            disabled:
                                              disabled ||
                                              _vm.deviceOptions.length === 0,
                                            options: _vm.deviceOptions
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.selectedTagValue(
                                                "device",
                                                $event
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          value: ""
                                                        }
                                                      },
                                                      [_vm._v("類別可複選")]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        "b-form-select",
                                        inputAttrs,
                                        false
                                      ),
                                      inputHandlers
                                    )
                                  )
                                ],
                                1
                              ),
                              tags.length > 0
                                ? _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-inline d-inline-block mt-3"
                                    },
                                    _vm._l(tags, function(tag) {
                                      return _c(
                                        "li",
                                        {
                                          key: tag,
                                          staticClass: "list-inline-item"
                                        },
                                        [
                                          _c(
                                            "b-form-tag",
                                            {
                                              attrs: {
                                                title: tag,
                                                disabled: disabled,
                                                variant: "info"
                                              },
                                              on: {
                                                remove: function($event) {
                                                  removeTag(tag),
                                                    _vm.removerTagValue(
                                                      "device",
                                                      tag
                                                    )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(tag))]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: this.tagSelectedList.device,
                        callback: function($$v) {
                          _vm.$set(this.tagSelectedList, "device", $$v)
                        },
                        expression: "this.tagSelectedList.device"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c(
                  "b-input-group",
                  { attrs: { size: "mg", prepend: _vm.$t("select.title_2") } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "type-number",
                        type: "number",
                        oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')"
                      },
                      on: { input: _vm.changeCapacity },
                      model: {
                        value: _vm.capacity,
                        callback: function($$v) {
                          _vm.capacity = $$v
                        },
                        expression: "capacity"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "b-card-group",
            {
              key: _vm.selectdSpace,
              staticClass: "mt-4 animated fadeInUp",
              attrs: { deck: "" }
            },
            [
              _c(
                "b-card",
                [
                  _c("b-img", {
                    attrs: {
                      src: _vm.spaceData["zh-tw"][_vm.selectdSpace].pic,
                      fluid: "",
                      center: "",
                      alt: "Fluid-grow image"
                    }
                  }),
                  _vm.selectdSpace == "展場空間"
                    ? _c("b-card-text", { staticClass: "mt-4" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_1.card.content")) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm.selectdSpace == "會議中心"
                    ? _c("b-card-text", { staticClass: "mt-4" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_2.card.content")) + " "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row mt-4" },
            _vm._l(_vm.spaceList, function(item) {
              return _c(
                "div",
                {
                  key: item.venue_space_id,
                  staticClass: "mb-4 col-lg-4 animated fadeInUp"
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "rental-venue/space/" + item.venue_space_id }
                    },
                    [
                      _c("div", { staticClass: "card act-card-hover" }, [
                        _c("img", {
                          staticClass: "card-img-top",
                          attrs: { src: item.path, alt: "Card image cap" }
                        }),
                        _c("div", { staticClass: "card-body" }, [
                          _c("small", [
                            _c("i", { staticClass: "fas fa-door-open" }),
                            _vm._v(_vm._s(item.space_name))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-footer text-right" },
                          _vm._l(item.spaceTag, function(tagItem, tagIndex) {
                            return _c(
                              "span",
                              {
                                key: tagIndex,
                                staticClass: "badge badge-info"
                              },
                              [_vm._v("# " + _vm._s(tagItem.tag_name))]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              class: [
                "row",
                "text-center",
                { "is-show-next-page": _vm.isShowNextPage }
              ]
            },
            [
              _c("div", { staticClass: "col-lg-12 read-more" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.getNextSpaceList()
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("section_2.pageBtn"))),
                    _c("i", { staticClass: "fas fa-chevron-right ml-2" })
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }