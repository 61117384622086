<template>
    <div>
        <Navbar />
        <Header :category="'organizers'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <RentalVenueBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import RentalVenueBody from './RentalVenueBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'RentalVenueMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_2_3"),
            }
        },
        components: {
            Navbar,
            Header,
            RentalVenueBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                selected: null,
                bannerTitle: "BOOKING<br>INQUIRY",
                borrowSpaceCrumb: [{
                    name : this.$t('navbar.menu_2')
                }]
            }
        }
    }
</script>

<style scoped>
    .solutions-area.section-width .container {
        max-width: 1000px;
    }

    .fa-eye::before{
        margin-right: 5px;
    }
    .card-footer {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
    }
    .card img {
        object-fit: cover;
        height: 250px;
    }

    .card-footer{
        color: #b5bac1;
    }
    .card-body{
        padding: 10px 20px;
    }
    .card-body small{
        color: #b5bac1;
        font-family: Noto Sans,Apple Casual,Corbel;
        font-size: 16px;
    }
    .card-body .card-text{
        font-weight: bold;
        font-size: 18px;
    }
    .custom-select:focus {
        box-shadow: none !important;
        border: 1px solid #ced4da;
    }

    .act-card-hover {
        border-radius: 18px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
        cursor: pointer;
        transition: 0.5s;
    }

    .act-card-hover:hover {
        transform: scale(1.02, 1.02);

    }

</style>